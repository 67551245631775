import type { EditorReadyOptions } from '@wix/platform-editor-sdk';
import { handleFirstInstall } from './first-install/handleFirstInstall';
import { setPortfolioPagesStates } from './first-install/utils/pagesServices';
import { editorAppEventListener } from './editorAppEventListener';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { AppDefId } from '../constants';

export const onEditorReady = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  if (options.firstInstall) {
    const areAppPagesInstalled = await editorSDK.pages.getApplicationPages('');
    if (areAppPagesInstalled.length >= 3) {
      await editorSDK.editor.deeplink.show('', {
        type: 'pagesPanel',
        params: [AppDefId],
      });
    } else {
      await handleFirstInstall(editorSDK, appDefId, options, flowAPI);
    }
  }
  setPortfolioPagesStates(editorSDK);
  editorAppEventListener(editorSDK, flowAPI, options.origin.type);
};
