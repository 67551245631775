import { AppPageData } from '../types/types';

export enum PortfolioPagesRole {
  Portfolio = 'portfolio',
  Collection = 'collection',
  Project = 'project',
  Tag = 'tag',
}
interface IAppPageData {
  [key: string]: AppPageData;
}

export const FeaturedCollectionWidgetData = {
  widgetId: 'ceecd61c-3aec-4639-b32c-cbfe1400e819',
  presetId: 'variants-l46r9mv9',
  mobilePresetId: 'variants-l46r9mva',
};

export const Pages: IAppPageData = {
  PORTFOLIO_PAGE: {
    pageId: 'Portfolio',
    title: 'Portfolio_Page_Title',
    widgetId: 'ccd857ff-153e-452c-8cea-3f3a8aafeb86',
    presetId: 'variants-kzve32d0',
    mobilePresetId: 'variants-l2uj4qo4',
    pageUriSEO: 'portfolio',
  },
  COLLECTION_PAGE: {
    pageId: 'Collection',
    title: 'Collection_Page_Title',
    widgetId: 'd6c045f0-5d83-4c6c-845e-9059ca159990',
    presetId: 'variants-kzwjby151',
    mobilePresetId: 'variants-l2uitzc0',
    pageUriSEO: 'collection',
  },
  PROJECT_PAGE: {
    pageId: 'Project',
    title: 'Project_Page_Title',
    widgetId: '437cf52e-f57d-449e-af48-96791677e471',
    presetId: 'variants-kzwh8ghy1',
    mobilePresetId: 'variants-l2uh6cod',
    pageUriSEO: 'project',
  },
  TAG_PAGE: {
    pageId: 'Tag',
    title: 'Tag_Page_Title',
    widgetId: 'e0a0078d-0b7b-448b-8286-634343625929',
    presetId: 'variants-kzwh8t9n1',
    mobilePresetId: 'variants-kzwh8t9n1',
    pageUriSEO: 'tag',
  },
};

export const classicDefaultInstallationSize = {
  width: 980,
  height: 500,
};
