import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: t('Widget_Name_Collections_Widget'),
    nickname: 'collectionWidget',
  });

  controllerBuilder.configureConnectedComponents(
    'collectionGallery',
    (componentBuilder) => {
      componentBuilder.gfpp().set('settings', { behavior: 'HIDE' });
      componentBuilder.gfpp('mobile').set('settings', { behavior: 'HIDE' });
    },
  );
};
